<template>
  <section>
    <div class="row">
      <div
        v-if="loading"
        class="col text-center">
        <coozzy-spinner />
      </div>
      <div
        v-if="!loading && !url && !hasSeveralUrls"
        class="col d-flex justify-content-center align-items-center font-weight-bold h1">
        n/a
      </div>
      <template v-if="!loading && hasSeveralUrls && !selectedElement">
        <template
          v-if="defaultReportings.length > 0">
          <div class="col-12">
            <coozzy-page-title :title="$tc('message.navigation.reporting.standardReporting')" />
          </div>
          <div
            v-for="item in defaultReportings"
            :key="item.url"
            class="col-12">
            <coozzy-card
              no-body
              class="cursor-pointer hover"
              @click="reportingSelected(item)">
              <div class="row p-3">
                <div class="col-12">
                  <h4 class="font-weight-normal mb-0">
                    {{ item.title }}
                  </h4>
                  <div
                    v-dompurify-html="item.subTitle"
                    class="mb-0 mt-1" />
                </div>
              </div>
            </coozzy-card>
          </div>
        </template>
        <template
          v-if="additionalReportings.length > 0">
          <div class="col-12">
            <coozzy-page-title :title="$tc('message.navigation.reporting.additionalReporting')" />
          </div>
          <div
            v-for="item in additionalReportings"
            :key="item.url"
            class="col-12">
            <coozzy-card
              no-body
              class="cursor-pointer hover"
              @click="reportingSelected(item)">
              <div class="row p-3">
                <div class="col-12">
                  <h4 class="font-weight-normal mb-0">
                    {{ item.title }}
                  </h4>
                  <div
                    v-dompurify-html="item.subTitle"
                    class="mb-0 mt-1" />
                </div>
              </div>
            </coozzy-card>
          </div>
        </template>
      </template>
      <div
        v-else-if="url"
        class="col">
        <iframe
          :id="'reporting-' + replaceURL(url)"
          :key="url"
          :src="url"
          style="min-width: 100%; border: none; min-height: 92vh;"
          allowtransparency
          @load="iframeLoaded()" />
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import ReportingApi from '@/misc/apis/ReportingApi'
import { user } from '@/mixins/user'
import CoozzySpinner from '@/framework/components/misc/CoozzySpinner.vue'
import Vue from 'vue'
import { iframeResizer } from 'iframe-resizer'
import CoozzyCard from '@/framework/components/card/CoozzyCard.vue'
import CoozzyPageTitle from '@/framework/layout/CoozzyPageTitle.vue'
import { ReportingReportingUrl } from '@/types/api'

export default Vue.extend({
  name: 'ReportingView',
  components: {
    CoozzyPageTitle,
    CoozzyCard,
    CoozzySpinner
  },
  mixins: [user],
  props: {
    selectedElement: {
      type: [String, Number],
      required: false,
      default: ''
    }
  },
  metaInfo() {
    return {
      title: this.$t('message.reporting.titles.overview')
    }
  },
  data() {
    return {
      loading: false as boolean,
      defaultReportings: [] as ReportingReportingUrl[],
      additionalReportings: [] as ReportingReportingUrl[],
      url: '' as string
    }
  },
  computed: {
    hasSeveralUrls() {
      return this.defaultReportings.length > 0 || this.additionalReportings.length > 0
    }
  },
  watch: {
    selectedElement(newVal, oldVal) {
      if (oldVal !== newVal) {
        this.$nextTick(() => {
          this.url = newVal
        })
      }
    }
  },
  mounted() {
    this.loading = true
    window.addEventListener('message', (event: MessageEvent) => {
      const data = event.data
      if (data.type === 'ic3-custom-headers-request') {
        const embeddedDiv = (data.ic3callerType === 'div')

        const target = !embeddedDiv
            ? (document.getElementById('reporting-' + this.replaceURL(this.url)) as HTMLIFrameElement)?.contentWindow
            : window

        target && target.postMessage(
            {
              type: 'ic3-custom-headers-reply',
              data: {
                headers: {
                  Authorization: `Bearer ${this.getAccessToken}`
                }
              }
            },
            '*'
        )
      }
    })
    ReportingApi.getMyReportings()
      .then(response => {
        this.defaultReportings = response.defaultReportings
        this.additionalReportings = response.additionalReportings
        setTimeout(() => {
          this.loading = false
        }, 1000)
      })
      .catch(e => {
        if (e.response.status !== 404) {
          Vue.toasted.show(this.$t('message.loadingErrors.reporting'), { type: 'error' })
          console.error(e)
        }
        this.loading = false
      })
  },
  methods: {
    reportingSelected(item: ReportingReportingUrl): void {
      this.$router.push({
        name: this.$route.name,
        query: {
          selectedElement: item.url
        }
      })
    },
    iframeLoaded(): void {
      window.scrollTo(0, 0)
      iframeResizer({ log: false }, `#reporting-${this.replaceURL(this.url)}`)
    },
    replaceURL(url: string): string {
      return url.replace(/[^a-zA-Z0-9]/g, '')
    }
  }
})
</script>

<style lang="scss" scoped>
.logo {
  display: block;
  width: 100%;
  height: 208px;
  object-fit: contain;
}

.single-name {
  height: 100%;
  text-align: center;
}

iframe {
  overflow-x: hidden;
  overflow-y: hidden;
}
</style>
